import request from '@/utils/request'

/**
 * 检验
 */
export const refreshFlag = {
    getGlucoseV2RefreshFlag() {
        return request({
            url: 'v/equipment/glucose/v2/refreshFlag',
            method: 'get',
        })
    },
    getPressureV2RefreshFlag() {
        return request({
            url: 'v/equipment/pressure/v2/refreshFlag',
            method: 'get',
        })
    },
}
