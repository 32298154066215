// 聚合接口
import request from '@/utils/request'

export const aggregate = {
    getV2PressureAggregate(data) {
        return request({
            url: 'v/equipment/pressure/v2/aggregate',
            method: 'get',
            params: data
        })
    },
    getV2GlucoseAggregate(data) {
        return request({
            url: 'v/equipment/glucose/v2/aggregate',
            method: 'get',
            params: data
        })
    },
}
