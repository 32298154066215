import request from '@/utils/request'

/**
 * 站点统计
 */
export const siteStatistics = {
    getV2Service(data) {
        return request({
            url: 'v/equipment/glucose/v2/statistics/service',
            method: 'get',
            params: data
        })
    },
    getPressureV2Service(data) {
        return request({
            url: 'v/equipment/pressure/v2/statistics/service',
            method: 'get',
            params: data
        })
    },

}
