import request from '@/utils/request'

/**
 * 监测人群年龄统计
 */
export const ageStatistics = {
    getGlucoseV2Age(data) {
        return request({
            url: 'v/equipment/glucose/v2/age',
            method: 'get',
            params: data
        })
    },
    getPressureV2Age(data) {
        return request({
            url: 'v/equipment/pressure/v2/age',
            method: 'get',
            params: data
        })
    },
}
