import request from '@/utils/request'

/**
 * 监测结果统计
 */
export const result = {
    getV2Result(data) {
        return request({
            url: 'v/equipment/glucose/v2/result',
            method: 'get',
            params: data
        })
    },
    getPressureV2Result(data) {
        return request({
            url: 'v/equipment/pressure/v2/result',
            method: 'get',
            params: data
        })
    },
}
