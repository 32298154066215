import request from '@/utils/request'

/**
 * 录入方式
 */
export const entering = {
    getV2Resource(data) {
        return request({
            url: 'v/equipment/glucose/v2/resource',
            method: 'get',
            params: data
        })
    },
    getPressureV2Resource(data) {
        return request({
            url: 'v/equipment/pressure/v2/resource',
            method: 'get',
            params: data
        })
    },
}
