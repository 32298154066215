<template>
  <div class="dialog">
    <el-dialog
      :modal="false"
      :visible.sync="dialogVisible"
      draggable
      :show-close="false"
      :width="dialogWidth"
      style="min-width: 600px"
      @closed="handleDialogVisibleClose"
      @open="handleDialogVisibleOpen"
    >
      <slot name="dialog"></slot>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: "30%",
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleOpen() {},
    handleDialogVisibleClose() {},
    handleDialogVisibleEnter() {},
    handleDialogVisibleOpen() {},
  },
  watch: {
    dialogVisible(newVal, oldVal) {
      this.dialogVisible = newVal;
    },
  },
};
</script>

<style >
.dialog .el-dialog {
  margin: 0 !important;
  box-shadow: none;
  /* background: rgba(0,179,250,0.1); */
  background: transparent;
  backdrop-filter: blur(20px);
  height: 100%;
}
</style>

<style lang="scss" scoped>
</style>