<template>
  <div class="container nodata">
    <!-- 标题 -->
    <div class="title-bg">
      <span class="title-color">使用率统计</span>
    </div>
    <dv-scroll-board
      :config="handleV2Usage"
      style="height: 250px"
      @click="handleGotoParticulars"
    />
    <!-- <div class="nodata-text" v-show="!handleV2Usage.data.length">
      暂无数据
    </div> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/config";
export default {
  name: "",
  components: {},
  props: {},
  computed: {
    ...mapState(getStore, ["handleV2Usage", "v2UsageList"]),
  },
  data() {
    return {
      config: {},
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    handleGotoParticulars(val) {
      const patientUserId = this.v2UsageList[val.rowIndex].patientUserId;
      this.$router.push(`/particulars?userId=${patientUserId}`);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .title-bg {
    height: 28px;
    background: url("~@/assets/chart_right_bg.png");
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 37px;
    .title-color {
      font-weight: normal;
      color: #ffffff;
      background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
</style>
