import request from '@/utils/request'

/**
 * 解绑绑定记录
 */
export const deviceBindingRecord = {
    getDeviceBindingRecord(data) {
        return request({
            url: 'v/equipment/glucose/v2/bind',
            method: 'get',
            params: data
        })
    },
    getPressureDeviceBindingRecord(data) {
        return request({
            url: 'v/equipment/pressure/v2/bind',
            method: 'get',
            params: data
        })
    },
}
