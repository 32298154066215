import request from '@/utils/request'

/**
 * 实时监测
 */
export const supervision = {
    getV2RealTime(data) {
        return request({
            url: 'v/equipment/glucose/v2/monitor/realTime',
            method: 'get',
            params: data
        })
    },
    getPressureV2RealTime(data) {
        return request({
            url: 'v/equipment/pressure/v2/monitor/realTime',
            method: 'get',
            params: data
        })
    },
}
