<template>
  <div>
    <div class="header">
      <span class="top_title fw">监测人群年龄统计</span>
    </div>
    <Echart :options="handleGlucoseV2Age" :height="`280px`"></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart/index.vue";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/config";

export default {
  name: "",
  components: {
    Echart,
  },
  computed: {
    ...mapState(getStore, ["glucoseV2AgeList", "handleGlucoseV2Age"]),
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  background-image: url("~@/assets/chart_top_bg.png");
  background-size: 100% 100%;
  background-position: center center;
}
.top_title {
  margin-left: auto;
  margin-right: 40px;
  font-size: 14px;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
