import { defineStore } from "pinia";
import { head } from "@/api/disease/head.js";
import { ageStatistics } from "@/api/disease/ageStatistics.js";
import { deviceBindingRecord } from "@/api/disease/deviceBindingRecord";
import { result } from "@/api/disease/result.js";
import { monitoringData } from "@/api/disease/monitoringData.js";
import { entering } from "@/api/disease/entering";
import { siteStatistics } from "@/api/disease/siteStatistics.js";
import { supervision } from "@/api/disease/supervision.js";
import { usage } from "@/api/disease/usage.js";
import { anomaly } from "@/api/disease/anomaly.js";
import { aggregate } from "@/api/disease/index.js";
import { handleString, handleStatusStr } from "@/utils";


export const getStore = defineStore('useData', {
    state: () => ({
        currentHospitalId: '',
        type: '',
        current: 1,
        province: {},
        // 头部数据
        V2Head: [
            { id: 1, label: "中心数量", value: "22" },
            { id: 2, label: "服务站数量", value: "153" },
            { id: 3, label: "医师总人数", value: "236" },
            { id: 4, label: "建档总人数", value: "143233", isSpace: true },
            { id: 5, label: "监测总人数", value: "101545", isSpace: true },
            { id: 6, label: "监测总次数", value: "103687" },
            { id: 7, label: "异常次数", value: "100111" },
            { id: 8, label: "4G设备绑定人数", value: "102235" },
        ],
        v2AgeList: {},
        V2ResultList: [],
        V2MonitorList: {},
        resourceList: {},
        serviceList: [],
        v2RealTimeList: [],
        v2UsageList: [],
        v2anomalyList: [],
        v2DeviceBindingList: [],
    }),
    getters: {
        // 处理 监测人群年龄统计 数据  
        handleGlucoseV2Age: (state) => {
            let cdata = {
                color: "#00FDFF",
                grid: {
                    width: '88%'
                },
                legend: {},
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                xAxis: {
                    type: "category",
                    // boundaryGap: false,
                    data: state.v2AgeList.xData ?? [],
                    axisLabel: {
                        fontSize: 10,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                series: [
                    {
                        data: state.v2AgeList?.serieData?.people ?? [],
                        type: "bar",
                        barWidth: 8,
                    },
                ],
            }
            return cdata
        },
        // 处理 head 数据
        handleV2Head: (state) => {
            return state.V2Head.map((item) => {
                if (item.title == '建档总人数' || item.title == '监测总人数') {
                    item.isSpace = true
                }
                return item
            })
        },
        // 监测结果统计
        handleV2Result: (state) => {
            let cdata = {
                color: ["#05FF00", '#0A4A9D', "#00FDFF", "#FFD700", "#FF3D3D"],
                grid: {
                    top: 50,
                    right: 0
                },
                tooltip: {
                    trigger: "item",
                    // show: true,
                    // trigger: "axis",
                    // axisPointer: {
                    //   type: "none",
                    // },
                },
                legend: {
                    show: false,
                },
                title: {
                    textStyle: {
                        color: "#999",
                        fontWeight: "normal",
                        fontSize: 14,
                    },
                },
                series: [
                    {
                        type: "pie",
                        radius: ["50%", "65%"],
                        center: ['50%', '55%'],
                        stillShowZeroSum: false,
                        avoidLabelOverlap: true,
                        minAngle: 25,
                        label: {
                            show: true,
                            position: "outside",
                            alignTo: "edge",
                            formatter: "{name|{b}} {ratio|{d}%}\n{value|{c} 次}",
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 15,
                            alignTo: 'labelLine',
                            rich: {
                                name: {
                                    color: "#fff",
                                    fontSize: 12,
                                    fontWeight: 700,
                                },
                                ratio: {
                                    color: "#fff",
                                    fontSize: 12,
                                    fontWeight: 700,
                                },
                                value: {
                                    fontSize: 10,
                                    color: "#999",
                                },
                            },
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 16,
                                fontWeight: "bold",
                            },
                        },
                        labelLine: {
                            show: true,
                            length: 10,
                            length2: 40,
                            smooth: 0.2,
                            // normal: {
                            //   show: true, //引导线显示
                            // },
                        },
                        labelLayout(params) {
                            return {
                                x: params.rect.x + 10,
                                y: params.rect.y + params.rect.height / 2,
                                verticalAlign: "middle",
                                align: "left",
                            };
                        },
                        data: state.V2ResultList.map(item => {
                            return { value: item.count, name: item.diabetesStatusStr }
                        }),
                    },
                ],
            }
            return cdata
        },
        // 监测数据
        handleV2Monitor: (state) => {
            console.log('V2MonitorList 12312', state.V2MonitorList)
            let cdata = {
                color: ["#00FDFF", "#0171FF"],
                grid: {
                    top: 40,
                    left: 60,
                    right: 60,
                },
                legend: {
                    icon: "roundRect",
                    top: 10,
                    right: 43,
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontSize: 8, // 设置 legend 中文字的字体大小为 14
                    },
                    data: ["已监测人数", "已监测次数"],
                },
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                },
                xAxis: {
                    type: "category",
                    // boundaryGap: true,
                    data: state.V2MonitorList.xData,
                    axisLabel: {
                        fontSize: 10,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                yAxis: [
                    // 人数
                    {
                        type: "value",
                        name: "(人数)",
                        nameLocation: "start",
                        minInterval: 1,
                        axisLabel: {
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "transparent", // 设置为透明颜色
                            },
                        },
                    },
                    // 次数
                    {
                        type: "value",
                        name: "(次数)",
                        nameLocation: "start",
                        minInterval: 1,
                        axisLabel: {
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "transparent", // 设置为透明颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "已监测人数",
                        type: "line",
                        // stack: "Total",
                        stack: '已监测人数',
                        symbol: "none",
                        areaStyle: {
                            origin: 'start'
                        },
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: state.V2MonitorList?.serieData?.people
                    },
                    {
                        name: "已监测次数",
                        yAxisIndex: 1,
                        type: "line",
                        symbol: "none",
                        stack: "已监测次数",
                        areaStyle: {
                            origin: 'start'
                        },
                        emphasis: {
                            focus: "series",
                            label: {
                                show: false, // 在渐变堆叠面积图的最高点显示数值
                            },
                        },
                        data: state.V2MonitorList?.serieData?.count
                    },
                ],
            }
            return cdata
        },
        // 录入方式统计
        handleV2Resource: (state) => {
            let cdata = {
                color: "#00FDFF",
                grid: {
                    width: "100%",
                },
                legend: {},
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                },
                yAxis: {
                    type: "value",
                    minInterval: 1,
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                xAxis: {
                    type: "category",
                    // boundaryGap: false,
                    data: state.resourceList.xData,
                    axisLabel: {
                        fontSize: 10,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "transparent", // 设置为透明颜色
                        },
                    },
                },
                series: [
                    {
                        data: state.resourceList?.serieData?.count,
                        type: "bar",
                        barWidth: 8,
                    },
                ],
            }
            return cdata
        },
        handleV2Service: (state) => {
            let config = {
                header: [
                    "医院名称",
                    "监测人数",
                    "监测次数",
                    "异常次数",
                    "4G设备绑定人数",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["124", "124", "124", "124", "124"],
                align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                ],
                data: state.serviceList.map(item => {
                    return [handleString(item.hospitalName), item.people, item.count, item.anomalyCount, item.fourGBindPeople]
                }),
            }
            return config
        },
        // 实时监测
        handleV2RealTimeList: (state) => {
            console.log('first', state.v2RealTimeList)
            let config = {
                header: [
                    "医院名称",
                    "姓名",
                    "年龄",
                    "性别",
                    "监测数值",
                    "监测结果",
                    "监测日期",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["145", "80", "80", "80", "145", "100", "145"],
                align: ["center", "center", "center", "center", "center", "center", "center"],
                data: state.v2RealTimeList.map(item => {
                    return [handleString(item.hospitalName), handleString(item.patientName), item.age, item.genderStr, item.glucoseScore, handleStatusStr(item.diabetesStatus), item.inspectionTimeStr]
                }),
            }
            return config
        },
        // 使用率统计
        handleV2Usage: (state) => {
            let config = {
                header: ["医院名称", "姓名", "绑定天数", "使用天数", "使用次数", "使用率"],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["145", "80", "145", "80", "80", "145"],
                align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                ],
                data: state.v2UsageList.map(item => {
                    return [handleString(item.hospitalName), handleString(item.patientName), item.bindFatalism, item.useFatalism, item.useCount, item.useRatio]
                }),
            }
            return config
        },
        // 异常统计
        handlev2anomalyList: (state) => {
            let config = {
                header: [
                    "医院名称",
                    "姓名",
                    "年龄",
                    "性别",
                    "监测数值",
                    "监测结果",
                    "监测日期",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["145", "80", "80", "80", "145", "100", "145"],
                align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                ],
                data: state.v2anomalyList.map(item => {
                    return [handleString(item.hospitalName), handleString(item.patientName), item.age, item.genderStr, item.glucoseScore, handleStatusStr(item.diabetesStatus), item.inspectionTimeStr]
                }),
            }
            return config
        },
        // 解绑/绑定记录
        handleV2DeviceBindingList: (state) => {
            let config = {
                header: [
                    "医院名称",
                    "姓名",
                    "设备号",
                    "状态",
                    "操作人",
                    "日期",
                ],
                headerBGC: "",
                oddRowBGC: "",
                rowNum: "4",
                evenRowBGC: "",
                waitTime: 3000,
                columnWidth: ["145", "80", "80", "80", "80", "145"],
                align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                ],
                data: state.v2DeviceBindingList.map(item => {
                    return [handleString(item.hospitalName), handleString(item.patientUserName), item.deviceSn, item.status === 'UNBIND' ? '已解绑' : '已绑定', handleString(item.operator), item.dateTime]
                }),
            }
            return config
        }
    },
    actions: {
        handleCurrentHospitalId(hospitalId) {
            this.currentHospitalId = hospitalId
        },
        handletype(type) {
            this.type = type
        },
        // 聚合接口 血压
        getV2PressureAggregate() {
            let monitorStartTime = "";
            let startTime = "";
            let type = "";
            let monitorEndTime = new Date().getTime();
            let endTime = new Date().getTime();
            let last30Days = new Date(new Date(monitorEndTime).toLocaleDateString()).getTime() - 30 * 3600 * 24 * 1000; // 最近30天
            let last365Days = new Date(new Date(monitorEndTime).toLocaleDateString()).getTime() - 365 * 3600 * 24 * 1000; // 最近365天/一年
            if (this.current === 1) {
                monitorStartTime = last30Days;
                startTime = last30Days;
                type = "MONTH";
            } else {
                monitorStartTime = last365Days;
                startTime = last30Days;
                type = "YEAR";
            }
            let request = { monitorStartTime, monitorEndTime,  type, hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            aggregate.getV2PressureAggregate(request).then((res) => {
                const { data } = res;
                // 实时监测 数据转换
                this.V2Head = data.head.sort((a, b) => a.index - b.index);
                this.serviceList = data.service;
                this.V2ResultList = data.result.serieData;
                this.v2DeviceBindingList = data.bind;
                this.v2RealTimeList = data.realTime;
                this.v2UsageList = data.usage;
                this.v2AgeList = data.age;
                this.v2anomalyList = data.anomaly;
                this.resourceList = data.resource;
                this.V2MonitorList = data.monitor;
            });
        },
        // 聚合接口 血糖
        getV2GlucoseAggregate() {
            let monitorStartTime = "";
            let startTime = "";
            let type = "";
            let monitorEndTime = new Date().getTime();
            let endTime = new Date().getTime();
            let last30Days = new Date(new Date(monitorEndTime).toLocaleDateString()).getTime() - 30 * 3600 * 24 * 1000; // 最近30天
            let last365Days = new Date(new Date(monitorEndTime).toLocaleDateString()).getTime() - 365 * 3600 * 24 * 1000; // 最近365天/一年
            if (this.current === 1) {
                monitorStartTime = last30Days;
                startTime = last30Days;
                type = "MONTH";
            } else {
                monitorStartTime = last365Days;
                startTime = last30Days;
                type = "YEAR";
            }
            let request = { monitorStartTime, monitorEndTime,  type, hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            aggregate.getV2GlucoseAggregate(request).then((res) => {
                const { data } = res;
                // 实时监测 数据转换
                this.V2Head = data.head.sort((a, b) => a.index - b.index);
                this.serviceList = data.service;
                this.V2ResultList = data.result.serieData;
                this.v2DeviceBindingList = data.bind;
                this.v2RealTimeList = data.realTime;
                this.v2UsageList = data.usage;
                this.v2AgeList = data.age;
                this.v2anomalyList = data.anomaly;
                this.resourceList = data.resource;
                this.V2MonitorList = data.monitor;
            });
        },
        // 监测人群年龄统计
        async getGlucoseV2Age() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await ageStatistics.getGlucoseV2Age(request)
            this.v2AgeList = data
        },
        async getPressureV2Age() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await ageStatistics.getPressureV2Age(request)
            this.v2AgeList = data
        },
        // 解绑/绑定记录
        async getDeviceBindingRecord() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await deviceBindingRecord.getDeviceBindingRecord(request)
            this.v2DeviceBindingList = data
        },
        async getPressureDeviceBindingRecord() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await deviceBindingRecord.getPressureDeviceBindingRecord(request)
            this.v2DeviceBindingList = data
        },
        // 头部数据
        async getV2Head() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await head.getV2Head(request)
            this.V2Head = data
        },
        async getPressureV2Head() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await head.getPressureV2Head(request)
            this.V2Head = data
        },
        // 监测结果统计
        async getV2Result() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await result.getV2Result(request)
            this.V2ResultList = data.serieData
        },
        async getPressureV2Result() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await result.getPressureV2Result(request)
            this.V2ResultList = data.serieData
        },
        // 监测数据
        async getV2Monitor() {
            let startTime = "";
            let type = "";
            let endTime = new Date().getTime();
            let last30Days = new Date(new Date(endTime).toLocaleDateString()).getTime() - 30 * 3600 * 24 * 1000; // 最近30天
            let last365Days = new Date(new Date(endTime).toLocaleDateString()).getTime() - 365 * 3600 * 24 * 1000; // 最近365天/一年
            if (this.current === 1) {
                startTime = last30Days;
                type = "MONTH";
            } else {
                startTime = last365Days;
                type = "YEAR";
            }
            let request = { startTime, endTime, type, hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await monitoringData.getV2Monitor(request)
            this.V2MonitorList = data
        },
        async getPressureV2Monitor() {
            let startTime = "";
            let type = "";
            let endTime = new Date().getTime();
            let last30Days = new Date(new Date(endTime).toLocaleDateString()).getTime() - 30 * 3600 * 24 * 1000; // 最近30天
            let last365Days = new Date(new Date(endTime).toLocaleDateString()).getTime() - 365 * 3600 * 24 * 1000; // 最近365天/一年
            if (this.current === 1) {
                startTime = last30Days;
                type = "MONTH";
            } else {
                startTime = last365Days;
                type = "YEAR";
            }
            let request = { startTime, endTime, type, hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await monitoringData.getPressureV2Monitor(request)
            this.V2MonitorList = data
        },
        handleSelectItem(val) {
            sessionStorage.setItem('monitoringData_type', val)
            const monitoringData_type = sessionStorage.getItem('monitoringData_type')
            if (monitoringData_type === "nearly_year") {
                this.current = 2;
            } else if (val === "nearly_day") {
                this.current = 1;
            }
            this.type === 'blood_pressure' ? this.getV2PressureAggregate() : this.getV2GlucoseAggregate()
        },
        // 录入方式统计
        async getV2Resource() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await entering.getV2Resource(request)
            this.resourceList = data
        },
        async getPressureV2Resource() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await entering.getPressureV2Resource(request)
            this.resourceList = data
        },
        // 站点统计
        async getV2Service() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await siteStatistics.getV2Service(request)
            this.serviceList = data
        },
        async getPressureV2Service() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await siteStatistics.getPressureV2Service(request)
            this.serviceList = data
        },
        // 实时监测
        async getV2RealTime() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await supervision.getV2RealTime(request)
            this.v2RealTimeList = data.map(item => {
                item.statusStr = item.diabetesStatusStr
                return item
            })
        },
        async getPressureV2RealTime() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await supervision.getPressureV2RealTime(request)
            this.v2RealTimeList = data.map(item => {
                item.statusStr = item.pressureStatusStr
                return item
            })
        },
        // 使用率统计
        async getV2Usage() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await usage.getV2Usage(request)
            this.v2UsageList = data
        },
        async getPressureV2Usage() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await usage.getPressureV2Usage(request)
            this.v2UsageList = data
        },
        // 异常监测
        async getV2Anomaly() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await anomaly.getV2Anomaly(request)
            this.v2anomalyList = data.map(item => {
                item.statusStr = item.diabetesStatusStr
                return item
            })
        },
        async getPressureV2Anomaly() {
            let request = { hospitalId: this.currentHospitalId, ...this.province }
            if (request.hospitalId === '') {
                delete request.hospitalId
            }
            const { data } = await anomaly.getPressureV2Anomaly(request)
            this.v2anomalyList = data.map(item => {
                item.statusStr = item.pressureStatusStr
                return item
            })
        },
    }
})