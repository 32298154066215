import request from '@/utils/request'

/**
 * 头部数据
 */
export const head = {
    getV2Head(data) {
        return request({
            url: 'v/equipment/glucose/v2/head',
            method: 'get',
            params: data
        })
    },
    getPressureV2Head(data) {
        return request({
            url: 'v/equipment/pressure/v2/head',
            method: 'get',
            params: data
        })
    },
}
