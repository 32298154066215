<template>
  <div class="container">
    <!-- 标题 -->
    <div class="title-bg">
      <span class="title-color">录入方式统计</span>
    </div>
    <!-- 图表 -->
    <Echart :options="handleV2Resource" :height="`280px`"></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart/index.vue";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/config";

export default {
  name: "",
  components: {
    Echart,
  },
  props: {},
  data() {
    return {
    };
  },
  computed:{
    ...mapState(getStore, ["handleV2Resource"])
  },
  created() {},
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.container {
  .title-bg {
    height: 28px;
    background: url("~@/assets/chart_right_bg.png");
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 37px;
    .title-color {
      font-weight: normal;
      color: #ffffff;
      background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
</style>
