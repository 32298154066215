import request from '@/utils/request'

/**
 * 监测数据
 */
export const monitoringData = {
    getV2Monitor(data) {
        return request({
            url: 'v/equipment/glucose/v2/monitor',
            method: 'get',
            params: data
        })
    },
    getPressureV2Monitor(data) {
        return request({
            url: 'v/equipment/pressure/v2/monitor',
            method: 'get',
            params: data
        })
    },
}
