import request from '@/utils/request'

/**
 * 异常监测
 */
export const anomaly = {
    getV2Anomaly(data) {
        return request({
            url: 'v/equipment/glucose/v2/monitor/anomaly',
            method: 'get',
            params: data
        })
    },
    getPressureV2Anomaly(data) {
        return request({
            url: 'v/equipment/pressure/v2/monitor/anomaly',
            method: 'get',
            params: data
        })
    },
}
