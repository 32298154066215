<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="header">
          <!-- 选择医院 -->
          <div class="search">
            <div style="display: inline-block">
              <span style="margin-right: 5px">{{ addressStr }}</span>
            </div>
            <span v-if="hospitalName" style="margin-right: 5px">{{
              handleString(hospitalName)
            }}</span>
            <span
              style="margin-left: 5px; cursor: pointer"
              @click="handleSelectHospital"
            >
              <i class="el-icon-setting"></i>
            </span>
          </div>
          <img
            src="@/assets/header_left.png"
            style="width: 758px; height: 35px"
          />
          <img
            src="@/assets/header_center.png"
            style="width: 404px; height: 72px"
          />
          <img
            src="@/assets/header_right.png"
            style="width: 758px; height: 35px"
          />
          <div class="type">
            <div class="nearly_day fw topBg">
              <span class="color" @click="GoToEcgUrl">心电</span>
            </div>
            <div
              @click="handleSelectItem('blood_pressure')"
              class="nearly_day fw"
              :class="[current === 1 ? 'topBg_current' : 'topBg']"
            >
              <span :class="[current === 1 ? 'current_color' : 'color']"
                >血压</span
              >
            </div>
            <div
              @click="handleSelectItem('blood_glucose')"
              class="nearly_day fw"
              :class="[current === 2 ? 'topBg_current' : 'topBg']"
            >
              <span :class="[current === 2 ? 'current_color' : 'color']"
                >血糖</span
              >
            </div>
          </div>
        </div>
        <div class="header-data">
          <div
            class="space"
            v-for="item in handleV2Head"
            :class="[item.isSpace ? 'space_m' : '']"
          >
            <dv-border-box-8 style="height: 100%">
              <div class="flex-auto-center" style="height: 100%">
                <div class="value">{{ item.count }}</div>
                <div class="label">{{ item.title }}</div>
              </div>
            </dv-border-box-8>
          </div>
        </div>
        <div class="body-box">
          <div class="first_line">
            <!-- 监测数据 -->
            <div class="space">
              <MonitoringData />
            </div>
            <!-- 站点统计 -->
            <div class="space"><SiteStatistics /></div>
            <!-- BD市场工作统计 -->
            <div class="space"><Result /></div>
          </div>
          <div class="second_line">
            <!-- 近两月待上传率等-->
            <div class="space"><DeviceBindingRecord /></div>
            <!-- 实时监测 -->
            <div class="space"><Supervision /></div>
            <!-- 使用率统计 -->
            <div class="space"><Usage /></div>
          </div>
          <div class="third_line">
            <!-- 异常预警 -->
            <div class="space"><AgeStatistics /></div>
            <!-- 实时建档 -->
            <div class="space"><Filing /></div>
            <!-- 库存top5 -->
            <div class="space"><TopFive /></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :modal="false"
      title="选择医院"
      :visible.sync="dialogVisible"
      width="30%"
      @open="handleOpen"
    >
      <el-form>
        <el-form-item label-width="100px" label="当前用户:">
          <div class="flex-center" style="width: 60%">
            <img
              src="@/assets/default-avatar.png"
              style="width: 50px; height: 50px; border-radius: 50%"
            />
            <span class="title-item" style="margin: 0 10px; max-width: 80px">{{
              userInfo.name
            }}</span>
            <span class="title-item">{{ userInfo.phone }}</span>
          </div>
        </el-form-item>
        <el-form-item v-if="!oneHospitalName" label-width="100px" label="选择省市区:">
          <el-cascader
            style="width: 100%"
            size="mini"
            v-model="address"
            :options="addressJson"
            :props="addressListProps"
            @change="selectAddrssChange"
            placeholder="请选择省市区"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item v-if="!oneHospitalName" label-width="100px" label="选择医院:">
          <el-cascader
            v-model="hospitalId"
            :options="options"
            :disabled="address.length !== 3"
            size="mini"
            style="width: 100%"
            placeholder="请选择医院"
            :props="cateListProps"
          ></el-cascader>
          <!-- <el-button
            style="margin-left: 10px"
            size="mini"
            type="primary"
            :disabled="!hospitalId.length"
            @click="hanldeConfirm"
            >确 定</el-button
          >
          <el-button size="mini" plain @click="hanldeReset">重 置</el-button> -->
        </el-form-item>
        <el-form-item label-width="100px" label="数据脱敏:">
          <el-checkbox-group v-model="desensitization">
            <el-checkbox label="是否数据脱敏"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label-width="100px" label="账号管理:">
          <el-popconfirm title="是否退出登录" @confirm="handleLogOut">
            <el-button slot="reference" size="mini">退出登录</el-button>
          </el-popconfirm>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button
          style="margin-left: 10px"
          size="mini"
          type="primary"
          @click="hanldeConfirm"
          >确 定</el-button
        >
        <!-- <el-button size="mini" @click="handleLogOut">退出登录</el-button> -->
        <el-button size="mini" plain @click="hanldeReset">重 置</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import { hospital } from "@/api/hospital.js";
import { refreshFlag } from "@/api/refreshFlag.js";
import { province } from "@/api/province.js";
import { logout } from "@/api/login.js";
import { loginUser } from "@/api/loginUser.js";
import { aggregate } from "@/api/disease/index.js";
import MonitoringData from "../components/monitoringData.vue";
import SiteStatistics from "../components/siteStatistics.vue";
import Result from "../components/result.vue";
import DeviceBindingRecord from "../components/deviceBindingRecord.vue";
import Supervision from "../components/supervision.vue";
import Usage from "../components/usage.vue";
import AgeStatistics from "../components/ageStatistics.vue";
import Filing from "../components/anomaly.vue";
import TopFive from "../components/entering.vue";
import EleDialog from "../../components/CustomDialog/index.vue";
import { getUrlParams, handleString, handleProvince, trimSpace } from "@/utils";

import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/config";

export default {
  mixins: [drawMixin],
  components: {
    MonitoringData,
    SiteStatistics,
    Result,
    DeviceBindingRecord,
    Supervision,
    Usage,
    AgeStatistics,
    Filing,
    TopFive,
    EleDialog,
  },
  data() {
    return {
      handleString,
      loading: false,
      desensitization: false,
      current: 1,
      cateListProps: {
        value: "id", //匹配响应数据中的id
        label: "name", //匹配响应数据中的name
        checkStrictly: true,
        children: "children", //匹配响应数据中的children
      },
      addressListProps: {
        value: "name", //匹配响应数据中的id
        label: "name", //匹配响应数据中的name
        checkStrictly: true,
        children: "districts", //匹配响应数据中的children
      },
      updatetimeing: null,
      options: [],
      hospitalName: "",
      addressStr: "",
      address: [],
      addressJson: [],
      hospitalId: [],
      hospitalList: [],
      dialogVisible: false,
      oneHospitalName: false,
      userInfo: {},
      bloodPressure: {
        highPressure: 60,
        depression: 60,
      },
      heartRateScore: 120,
    };
  },
  computed: {
    ...mapState(getStore, ["type", "handleV2Head"]),
  },
  created() {
    this.desensitization =
      JSON.parse(sessionStorage.getItem("desensitization")) ?? false;
    let currentHospitalId = sessionStorage.getItem("currentHospitalId");
    let hospitalIdList = sessionStorage.getItem("hospitalIdList") ?? [];
    let currentHospitalName = sessionStorage.getItem("currentHospitalName");
    let province = JSON.parse(sessionStorage.getItem("province")) ?? [];
    this.address = trimSpace(province);
    this.addressStr = this.address.join("/");
    if (currentHospitalName) {
      this.hospitalName = currentHospitalName;
      this.hospitalId = hospitalIdList.split(",");
      getStore().$patch((store) => {
        store.currentHospitalId = this.hospitalId.length
          ? this.hospitalId[this.hospitalId.length - 1]
          : "";
      });
    }
  },
  mounted() {
    this.getProvince();
    this.getloginUser();
    const type = sessionStorage.getItem("disease_type");
    let province = handleProvince(
      JSON.parse(sessionStorage.getItem("province")) ?? []
    );
    getStore().$patch((store) => {
      store.type = type;
      store.province = province;
    });
    if (type == "blood_glucose") {
      this.current = 2;
    } else {
      this.current = 1;
    }
    this.getHospitalList(province);
    // 定时器
    this.changeTiming();
    this.handleSwitchType();
  },
  beforeDestroy() {
    clearInterval(this.updatetimeing);
    this.updatetimeing = null;
  },
  methods: {
    ...mapActions(getStore, [
      "handletype",
      "getV2GlucoseAggregate",
      // "getGlucoseV2Age",
      // "getDeviceBindingRecord",
      // "getV2Head",
      // "getV2Result",
      // "getV2Monitor",
      // "getV2Resource",
      // "getV2Service",
      // "getV2RealTime",
      // "getV2Usage",
      // "getV2Anomaly",
      // 血压
      "getV2PressureAggregate",
      // "getPressureV2Head",
      // "getPressureV2Monitor",
      // "getPressureV2Service",
      // "getPressureV2Result",
      // "getPressureV2RealTime",
      // "getPressureV2Usage",
      // "getPressureV2Age",
      // "getPressureV2Anomaly",
      // "getPressureV2Resource",
      // "getPressureDeviceBindingRecord",
    ]),
    getloginUser() {
      loginUser.getData().then((res) => {
        let { name, phone } = res.data;
        this.userInfo = { name, phone };
      });
    },
    // 退出登录
    handleLogOut() {
      logout.postLogout().then((res) => {
        if (res.code === "OK") {
          sessionStorage.clear();
          this.$router.replace("/");
        }
      });
    },
    getHospitalList(province) {
      hospital.getData(province).then(async (res) => {
        let options = res.data;
        this.options = await this.getTreeData(options);
        if (res.data.length === 1) {
          const hospitalInfo = this.handleDistrictsLength(res.data[0].children);
          if (hospitalInfo) {
            this.oneHospitalName = true
            this.hospitalName = hospitalInfo[0].name;
          }
        }
      });
    },
    handleDistrictsLength(districts) {
      if (districts.length === 1) {
        if (districts[0].children) {
          return this.handleDistrictsLength(districts[0].children);
        } else {
          return districts;
        }
      } else {
        if (districts.children) {
          return districts[0].children;
        } else {
          return districts;
        }
      }
    },
    getProvince() {
      province.getProvince().then((res) => {
        this.addressJson = this.getAddressData(res.data.districts);
      });
    },
    // 递归处理json文件的最后一级
    getAddressData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].districts.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].districts = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getAddressData(data[i].districts);
        }
      }
      return data;
    },
    // 递归处理json文件的最后一级
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      data.map((item) => {
        item.name = this.handleString(item.name);
        return item;
      });
      return data;
    },
    GoToEcgUrl() {
      this.$router.push("ecg");
    },
    handleSelectHospital() {
      let province = JSON.parse(sessionStorage.getItem("province")) ?? [];
      this.address = trimSpace(province);
      let hospitalIdList = sessionStorage.getItem("hospitalIdList") ?? [];
      if (hospitalIdList instanceof Array) {
        this.hospitalId = [];
      } else {
        this.hospitalId = hospitalIdList?.split(",");
      }
      this.dialogVisible = true;
    },
    handleOpen() {
      const hosArr = sessionStorage.getItem("hospitalIdList")?.split(",");
      let province = JSON.parse(sessionStorage.getItem("province")) ?? [];
      if (hosArr) {
        this.hospitalId = hosArr;
      }
      if (province.length) {
        province = handleProvince(province);
        this.getHospitalList(province);
      }
    },
    hanldeConfirm() {
      this.hospitalId = trimSpace(this.hospitalId);
      if (this.hospitalId.length > 0) {
        sessionStorage.setItem("hospitalIdList", this.hospitalId);
        sessionStorage.setItem("hosArr", JSON.stringify(this.hospitalId));
        this.dialogVisible = false;
        hospital
          .getInfo(this.hospitalId[this.hospitalId.length - 1])
          .then((res) => {
            this.hospitalName = res.data.name;
            sessionStorage.setItem("currentHospitalName", res.data.name);
            sessionStorage.setItem("currentHospitalId", res.data.id);
            getStore().$patch((store) => {
              store.currentHospitalId = res.data.id;
            });
            sessionStorage.setItem("province", JSON.stringify(this.address));
            sessionStorage.setItem("desensitization", this.desensitization);
            setTimeout(() => {
              location.reload();
            }, 200);
          });
      } else {
        sessionStorage.removeItem("currentHospitalName");
        sessionStorage.removeItem("currentHospitalId");
        sessionStorage.setItem("province", JSON.stringify(this.address));
        sessionStorage.setItem("desensitization", this.desensitization);
        setTimeout(() => {
          location.reload();
        }, 200);
      }
    },
    hanldeReset() {
      // sessionStorage.clear();
      sessionStorage.removeItem("currentHospitalName");
      sessionStorage.removeItem("currentHospitalId");
      sessionStorage.removeItem("hosArr");
      sessionStorage.removeItem("province");
      sessionStorage.removeItem("hospitalIdList");
      location.reload();
      this.dialogVisible = false;
    },
    handleSelectItem(type) {
      sessionStorage.setItem("disease_type", type);
      this.handletype(type);
      if (type == "blood_glucose") {
        this.current = 2;
      } else {
        this.current = 1;
      }
      this.handleSwitchType();
    },
    selectAddrssChange(val) {
      this.hospitalId = [];
      let province = handleProvince(val);
      this.getHospitalList(province);
    },
    // 切换 血糖 血压
    handleSwitchType() {
      if (this.current == 1) {
        this.getV2PressureAggregate();
        // 血压
        // this.getPressureV2Head();
        // this.getPressureV2Monitor();
        // this.getPressureV2Service();
        // this.getPressureV2Result();
        // this.getPressureV2RealTime();
        // this.getPressureV2Usage();
        // this.getPressureV2Age();
        // this.getPressureV2Anomaly();
        // this.getPressureV2Resource();
        // this.getPressureDeviceBindingRecord();
      } else {
        // 血糖
        this.getV2GlucoseAggregate();
        // this.getGlucoseV2Age();
        // this.getDeviceBindingRecord();
        // this.getV2Head();
        // this.getV2Result();
        // this.getV2Monitor();
        // this.getV2Resource();
        // this.getV2Service();
        // this.getV2RealTime();
        // this.getV2Usage();
        // this.getV2Anomaly();
      }
    },
    changeTiming() {
      this.updatetimeing = setInterval(() => {
        if (this.current == 1) {
          refreshFlag.getPressureV2RefreshFlag().then((res) => {
            if (res.data) {
              this.handleSwitchType();
            }
          });
        } else {
          refreshFlag.getGlucoseV2RefreshFlag().then((res) => {
            if (res.data) {
              this.handleSwitchType();
            }
          });
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  .type {
    position: absolute;
    top: 26px;
    left: 24px;
    display: flex;
  }
}
.header-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  padding: 0 16px;
  .space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 227px;
    height: 107px;
    background-image: url("~@/assets/header_space.png");
    background-size: 100% 100%;
    .label {
      font-size: 12px;
      margin-top: 15px;
    }
    .value {
      // font-family: 黑体 !important;
      // font-family: HeadLineA Regular !important;
      font-size: 30px;
      line-height: 30px;
      font-weight: 700;
      background-image: linear-gradient(to top, #01e0ff, #0171ff);
      -webkit-background-clip: text;
      color: transparent;
    }
  }
}

.space_m {
  background-image: url("~@/assets/header_space_m.png") !important;
}
.search {
  position: absolute;
  right: 35px;
  top: 26px;
  font-size: 14px;
}

.nearly_day,
.nearly_year {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 81px;
  height: 22px;
  margin-left: 10px;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-size: 12px;
  cursor: pointer;
}

.current_color {
  background: linear-gradient(180deg, #eeeeee 0%, #e7c618 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color {
  background: linear-gradient(180deg, #eeeeee 0%, #84e2ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.topBg {
  background-image: url("~@/assets/chart_space.png");
  background-size: 100% 100%;
  background-position: center center;
}
.topBg_current {
  background-image: url("~@/assets/chart_space_current.png");
  background-size: 100% 100%;
  background-position: center center;
}

.user_info {
  display: flex;
  justify-content: space-evenly;
  .dialog_box {
    color: #fff;
    box-sizing: border-box;
    width: 382px;
    height: 898px;
    padding: 48px 31px;
    border-radius: 30px;
    background: rgba(0, 179, 250, 0.1);
    backdrop-filter: blur(20px);
  }
}

.report_space {
  width: 211px;
  height: 30px;
  margin-top: 30px;
  background: url("~@/assets/report_space.png");
  background-size: cover;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
}

.number {
  font-size: 34px;
  margin-left: 8px;
}
// 进度条
.progress {
  color: #fff;
  margin-bottom: 64px;
  .glucose {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    font-size: 14px;
    font-weight: 700;
    &-low,
    &-normal,
    &-high {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .space {
        position: absolute;
        left: -29px;
        top: -42px;
        width: 58px;
        height: 56px;
        display: flex;
        justify-content: center;
        padding-top: 15px;
        box-sizing: border-box;
        font-weight: 700;
        color: #000;
        // background: url("~@/assets/glucose.png") no-repeat;
        background-size: 100% 100%;
        background-position: center center;
      }
    }
    &-low {
      width: 103px;
      height: 36px;
      background: #d5b436;
    }
    &-normal {
      position: relative;
      width: 129px;
      height: 36px;
      background: #2d9386;
      .standard-low {
        position: absolute;
        bottom: -21px;
        left: -10px;
      }
      .standard-high {
        position: absolute;
        bottom: -21px;
        right: -8px;
      }
    }
    &-high {
      width: 103px;
      height: 36px;
      background: #c3513a;
    }
  }
  .swiper {
    display: flex;
    flex-direction: column;
    .systolic-pressure {
      display: flex;
      justify-content: center;
      height: 92px;
      font-size: 14px;
      font-weight: 700;
      border: 1.5px solid rgba(255, 255, 255, 0.5);
      .swiper-unit {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
      }
      .low-tension {
        position: relative;
        width: 103px;
        color: #d5b436;
        .block {
          position: absolute;
          bottom: 15px;
          right: 0;
          width: 90%;
          height: 6px;
          background: #d5b436;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          .block-value {
            position: absolute;
            left: -5px;
            bottom: -15px;
            width: 40px;
            height: 34px;
            background: url("~@/assets/fixed_space.png") no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-weight: 700;
            z-index: 99999;
            div {
              z-index: 999;
            }
          }
        }
      }
      .normal {
        position: relative;
        width: 127px;
        height: 90px;
        color: #2d9386;
        border-left: 1.5px solid rgba(255, 255, 255, 0.5);
        border-right: 1.5px solid rgba(255, 255, 255, 0.5);
        .block {
          position: absolute;
          bottom: 16px;
          right: -1px;
          left: -1px;
          width: 130px;
          height: 6px;
          background-color: #2d9386;
          .block-value {
            position: absolute;
            left: -5px;
            bottom: -15px;
            width: 40px;
            height: 34px;
            background: url("~@/assets/fixed_space.png") no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-weight: 700;
            z-index: 99999;
            div {
              z-index: 999;
            }
          }
        }
      }
      .high-tension {
        position: relative;
        width: 103px;
        color: #c3513a;
        .block {
          position: absolute;
          bottom: 15px;
          left: 0;
          width: 90%;
          height: 6px;
          background: #c3513a;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          .block-value {
            position: absolute;
            left: -5px;
            bottom: -15px;
            width: 40px;
            height: 34px;
            background: url("~@/assets/fixed_space.png") no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-weight: 700;
            z-index: 99999;
            div {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
// 进度条2
.swiper {
  display: flex;
  flex-direction: column;
  .systolic-pressure {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    height: 92px;
    font-size: 14px;
    font-weight: 700;
    border: 1.5px solid #566263;
    .swiper-unit {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
    }
    .low-tension {
      position: relative;
      width: 90px;
      color: #ffd435;
      .block {
        position: absolute;
        bottom: 15px;
        right: 0;
        width: 90%;
        height: 6px;
        background-color: #ffd435;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        .block-value {
          position: absolute;
          left: -5px;
          bottom: -15px;
          width: 40px;
          height: 34px;
          background: url("~@/assets/fixed_space.png") no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          font-weight: 700;
          z-index: 99999;
          div {
            z-index: 999;
          }
        }
      }
    }
    .normal {
      position: relative;
      width: 127px;
      height: 90px;
      color: #00ffdb;
      border-left: 1.5px solid #566263;
      border-right: 1.5px solid #566263;
      .block {
        position: absolute;
        bottom: 16px;
        right: -1px;
        left: -1px;
        width: 127px;
        height: 6px;
        background-color: #00ffdb;
        .block-value {
          position: absolute;
          left: -5px;
          bottom: -15px;
          width: 40px;
          height: 34px;
          background: url("~@/assets/fixed_space.png") no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          font-weight: 700;
          z-index: 99999;
          div {
            z-index: 999;
          }
        }
      }
    }
    .high-tension {
      position: relative;
      width: 90px;
      color: #ff5833;
      .block {
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 90%;
        height: 6px;
        background-color: #ff5833;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        .block-value {
          position: absolute;
          left: -5px;
          bottom: -15px;
          width: 40px;
          height: 34px;
          background: url("~@/assets/fixed_space.png") no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          font-weight: 700;
          z-index: 99999;
          div {
            z-index: 999;
          }
        }
      }
    }
  }
}
</style>
