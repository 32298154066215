<template>
  <div class="nodata">
    <div class="header">
      <span class="top_title fw">解绑/绑定记录</span>
    </div>
    <dv-scroll-board
      :config="handleV2DeviceBindingList"
      style="height: 250px"
      @click="handleGotoParticulars"
    />
    <!-- <div class="nodata-text" v-show="!handleV2DeviceBindingList.data.length">暂无数据</div> -->
  </div>
</template>

<script>
import Echart from "@/common/echart/index.vue";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/config";
export default {
  name: "",
  components: { Echart },
  props: {},
  computed: {
    ...mapState(getStore, ["v2DeviceBindingList","handleV2DeviceBindingList"]),
  },
  data() {
    return {
      config: {
        header: ["医院名称", "姓名", "设备号", "状态", "操作人", "日期"],
        headerBGC: "",
        oddRowBGC: "",
        rowNum: "4",
        evenRowBGC: "",
        waitTime: 3000,
        // columnWidth: ["145", "80", "80", "80", "145", "145"],
        align: ["center", "center", "center", "center", "center", "center"],
        data: [["医院", "姓名", "设备号", "状态", "操作人", "日期"]],
      },
    };
  },
  mounted() {},
  methods: {
    handleGotoParticulars(val) {
      const patientUserId = this.v2DeviceBindingList[val.rowIndex].patientUserId;
      this.$router.push(`/particulars?userId=${patientUserId}`);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  background-image: url("~@/assets/chart_top_bg.png");
  background-size: 100% 100%;
  background-position: center center;
}
.top_title {
  margin-left: auto;
  margin-right: 40px;
  font-size: 14px;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
