import request from '@/utils/request'

/**
 * 使用率统计
 */
export const usage = {
    getV2Usage(data) {
        return request({
            url: 'v/equipment/glucose/v2/usage',
            method: 'get',
            params: data
        })
    },
    getPressureV2Usage(data) {
        return request({
            url: 'v/equipment/pressure/v2/usage',
            method: 'get',
            params: data
        })
    },
}
